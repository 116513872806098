/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import LocalizedStrings from "react-localization";

export const text = new LocalizedStrings({
  en: {
    title: "Highway 9 Networks Mobile Cloud Console",
    passwordEditInfo : 'To change the password, go to ',
    zone: {
      editGroup: "Edit VMZ Group",
      addGroup: "Add VMZ Group",
      deleteZoneGroup: "Are you sure you want to delete this VMZ Group?",
      deleteZone: "Are you sure you want to delete VMZ ",
    },
    LogLevelGuide: "to change the log level type you have to edit it in edit edge button",
    RemoteSubNetValidation:
      "Multiple remote subnet can be configured in comma separated format. Ex: 192.168.1.0/16, 10.0.3.0/8",
    enbID:
    "MacroID can have a maximum 20-bit value ranging from 1 to 1048575.",
    enbIDList : "Comma separated Macro eNB-IDs. Each eNB-ID can have a maximum 20-bit value ranging from 1 to 1048575. For ex: 99588, 99591, 99599",
    tacList:
    "Multiple tac's can be added by comma separated values. EX: 63, 64",
    OperatorPLNMValidation:
      //  "Multiple PLMNs can be added by comma separated values with MAX count of 6. Ex: 00101, 315010, 310411",
      "PLMN number must have min 5 and max count 6. Ex: 00101 / 315010 / 310411",
    OperatorRadioPLNMValidation:
      "Multiple PLMNs can be added by comma separated values with MAX count of 6. Ex: 00101, 315010, 310411",
    radioImportCheck: "Preserve old signature if there is no change in the radio settings",
    vmcUpdate: "This VMC was upgraded. You need to refresh this page to see the upgraded UI.",
    mobilityDefinitions:
      "The cell to which a UE is connected is known as the serving cell. The other cells which are in the coverage area and UE can connect to are known as the neighboring cells. When the serving cell has the poor coverage and the neighboring cell becomes better than the serving cell, a handover is performed and the UE is connected to the neighboring cell. \n \nThe following parameters must be configured for the efficient handovers: ",
    enablingEdgeSupport: "Enabling Edge Support may take a few seconds. Please wait...",
    disablingEdgeSupport: "Disabling Edge Support may take a few seconds. Please wait...",
    exportingRadios: `Exporting radios may take a few seconds. Please wait until the download starts.`,
    exportingOperator: `Exporting operator may take a few seconds. Please wait until the download starts.`,
    importingRadios: `Importing radios may take a few seconds. Please wait`,
    exportingKPIS: `Exporting KPIs may take a few seconds. Please wait until the download starts.`,
    exportingCIQs: `Exporting Radio (CIQ) may take a few seconds. Please wait until the download starts.`,
    A1ThresholdDef:
      "Stop Searching: Triggered when serving cell becomes better than threshold. Indicates coverage is good, so UE should stop searching for better cells.",
    A2ThresholdDef:
      "Start Searching: Triggered when serving cell becomes worse than threshold. Indicates coverage is bad, so UE should search for better cells.",
    A3ThresholdDef:
      "The Event A3 is triggered when a neighbouring cell becomes better than the serving cell by an offset. The offset can be either positive or negative.",

    A2Note:
      "Note: While searching the UE will consume more power, so it is recommended to configure the threshold value to be less than the value of A1.",
    radioBandwidthInfo: "Bandwidth can be be changed in parent radio group settings.",
    A5ThresholdDef:
      "Perform Handover: Triggered when the serving cell becomes worse than threshold1 and the neighboring cell becomes better than threshold2. Indicates neighboring cell is better than serving cell, so UE should perform handover and connect to the neighboring cell.",

    radioCPIError: `CPI Signatures are missing from the Radio.`,
    radioImportFileError: `No data is marked for UPDATE or DELETE found in excel file. Please upload the correct one to proceed.`,
    ANR: "Automatic Neighbor Relation (ANR)",
    ANRDef: "Start Reporting: UE reports the neighboring cells for the efficient handovers between the radios.",
    ANRNote: "Note: ANR Thresholds must be slightly more aggressive than the A5 thresholds defined above.",

    X2: "X2 Interface",
    X2Def: "X2 interface must be enabled for the smoother and faster handover between the radios.",
    X2Note: "Note: When X2 interface is enabled, IPSEC must be turned off on a radio.",
    hysteresisText:
      "Threshold to make sure that the neighboring cell is really better than the serving cell before handover. This is to prevent unnecessary handovers.",
    timetoTrigger: "Time to wait for neighboring cell to remain better before the handover is initiated",
    borderRadioHelpText:
      "Mark this radio as a border radio if this the coverage area for this radio and one of the carrier radio's overlap.",
    borderRadioGlobalConfig:
      "A radio connected to a Carrier Gateway Edge can be marked as a border radio if it's coverage area overlaps with the coverage area of the carrier. This property is not applicated for radios connected to only Private Edge's. Any radio not classified as a border radio is automatically considered as a non-border radio. To mark a radio as a border radio go to the 'Edit Radio' dialog on the Radio AP page. Border radios needs special configuration which can be set below.",
    edgeNetworkExtensionDeviceNetwork : "The devices configured with Static IP address will use the Gateway IP/DNS configured below. \n Devices configured via DHCP will not need this configuration, they will use the Gateway/DNS from the DHCP Offer.",
    downloadTechBundle: "GENERATE_TECH_SUPPORT_BUNDLE",
    startPcap: "START_PACKET_CAPTURE",
    stopPcap: "STOP_PACKET_CAPTURE",
    downloadMib: "DOWNLOAD_MIB",
    monitoring: "monitoringSystem",
    monitor: "Monitor",
    systemInfo: "Diagnostics Info",
    logFiles: "Log Files",
    logFilesDesc: " (Includes syslogs and MME logs)",
    coreDumps: "Core Dumps",
    packetCapture: "Packet Capture(control packets only)",
    systemInfoDesc: " (Includes shellcommandclientlogs, health_cli, ifconfig, hostname, gateway ping, ipsec)",
    download: "Download",
    downloadTech: "Tech Files",
    pcapFiles: "PACKET CAPTURE",
    generateBundle: "Generate Tech Support Bundle",
    downloading: "Downloading",
    downloadReady: "Download Ready",
    downloadFailed: "Download Failed",
    notes: "NOTES",
    logs: "LOGS",
    authorId: "Author ID",
    headerTitle: "Mobile Cloud - ",
    dashboard: "Dashboard",
    summary: "Summary",
    connectedDevices: "Connected Devices",
    devices: "Devices",
    RAN: "Radio AP",
    metrics: "Metrics",
    simulators: "Simulators",
    simulator: "Simulator Appliance",
    administration: "Administration",
    users: "Users",
    profile: "Profile",
    apiDocs: "API Documentation",
    logout: "Logout",
    radios: "Radio AP",
    lastSeenAtRadioAp: "Last Seen At Radio AP",
    tenants: "Tenants",
    serviceInstances: "Service Instances",
    edge: "Edge",
    edges: "Edges",
    cloudType: "Cloud Type",
    location: "Location",
    subscriber: "Device",
    subscribers: "Device Group",
    export: "Export",
    import: "Import",
    DNN: "Network",
    DNNs: "Networks",
    policy: "Application",
    policies: "Applications",
    data: "Data",
    alarms: "Alarms",
    healthy: "Healthy",
    total: "Total",
    connected: "Connected",
    activated: "Activated",
    dataSubText: "Total",
    concatSubText: "{0}/{1}",
    concatSubTexts: "{0}/{1}/{2}",
    name: "Name",
    type: "Type",
    cellId: "Cell Id",
    health: "Health",
    creationTime: "Creation Time",
    lastSyncTime: "Last Sync Time",
    controller: "Controller",
    activeSubscribers: "Active Devices",
    radio: "Radio",
    concatTwoStrings: "{0} {1}",
    deviceGroupWarning : `Device Group {0} is not part of any Virtual Mobile Zone(VMZ). Please configure this deviceGroup under one of the VMZs or create a new VMZ with this deviceGroup.`,
    status: "Publish Status",
    statusMessage: "Status Message",
    statusIndicator: "Signal Strength",
    certificateConfig: "Certificate Config",
    baicells: "Radio Config",
    system: "System",
    certStore: "CertStore",
    configureIPSECEndpoint: "Configure IPSEC Endpoint",
    gateway: "Gateway",
    leftID: "Left ID",
    rightID: "Right ID",
    rightSubnetID: "Right Subnet",
    leftSubnetID: "Left Subnet",
    leftCert: "Left Cert",
    secretKey: "Secret Key",
    leftAuth: "Left Auth",
    rightAuth: "Right Auth",
    leftSourceIP: "Left Source IP",
    network: "Network",
    IPSecMMEPool: "IPSec/MME Pool",
    MMEConfiguration: "MME Configuration",
    MMEIPAddress: "MME IP Address",
    BTSInfo: "BTS Info",
    quickSetting: "Quick Setting",
    downloadCertificateFiles: "Download Certificate Files",
    IPAddress: "IP Address",
    checkInTime: "Check In Time",
    edit: "Edit",
    delete: "Delete",
    disable: "Disable",
    enable: "Enable",
    refresh: "Refresh",
    installationSteps: "Installation Steps",
    ID: "ID",
    source: "Source",
    IP: "IP",
    port: "Port",
    destination: "Destination",
    destinationFqdn: "Destination FQDN",
    sourceFqdn: "Source FQDN",
    destinationPort: "DPort",
    sourcePort: "SPort",
    direction: "Direction",
    protocol: "Protocol",
    action: "Action",
    actions: "ACTIONS",
    add: "Add",
    modify: "Modify",
    radioReboot: "REBOOT",
    reboot: "Reboot",
    radioForceConfigure: "FORCE_SYNC_AND_REBOOT",
    forceConfigure: "Force Sync and Reboot",
    email: "Email",
    state: "State",
    IMSI: "IMSI",
    IMSIType: "IMSI Type",
    isActivated: "Is Activated?",
    ICCID: "ICCID",
    key: "Key",
    opc: "Opc",
    active: "Active",
    inactive: "Inactive",
    OTP: "OTP",
    PLMNsetting: "PLMN Settings",
    SASsetting: "SAS Settings",
    NetworkSettings: "Network Settings",
    FullNetworkName: "Full Network Name",
    simulatorVM: "Radio Simulator",
    UESimulator: "UE Simulator",
    simulatedVM: "Radio Simulator",
    connect: "Connect",
    disconnect: "Disconnect",
    ping: "Ping",
    tracepath: "Tracepath",
    Wget: "Wget",
    speedTest: "Speed test",
    iperfUpload: "Iperf Upload",
    iperfDownload: "Iperf Download",
    output: "Output",
    notImplemented: "Not Implemented",
    companyName: "Highway 9 Networks",
    loginTitle: "Mobile Cloud",
    resetTitle: "Reset Password",
    userName: "Username",
    password: "Password",
    confirmPassword: "Confirm Password",
    passwordNotMatch: "Password does not match",
    passwordTooShort: "Password is too short",
    login: "Login",
    reset: "Reset",
    resetConnection: "Reset Connection",
    serial: "Serial Number",
    RFStatus: "RF",
    activeStatus: "Cloud",
    CPConnection: "Edge",
    sasStatus: "SAS",
    EUDCount: "EUD #",
    MACAddress: "MAC",
    MACAddressInfo:
      "This MAC address is only used to get the DHCP lease for the device. The datapath will use the connected Edge appliance's uplink MAC",
    uptime: "Uptime",
    save: "Save",
    cancel: "Cancel",
    stop: "Stop",
    deleteMessage: "Are you sure you want to delete",
    disableMessage: "Are you sure you want to disable",
    enableMessage: "Are you sure you want to enable",
    stopMessage: "Are you sure you want to stop?",
    rebootMessage: "Are you sure you want to reboot",
    refreshMessage: "Are you sure you want to refresh the CMPv2 certificates",
    upgrade: "Upgrade",
    upgradeSchedule: "Upgrade Schedule:",
    upgradeNow: "Upgrade Now",
    upgradeAt: "Upgrade at:",
    upgradeType: "Select Upgrade Type:",
    upgradeVm: "Upgrade VM",
    replaceVm: "Replace VM",
    upgradeOtherReleases: "Upgrade to other release:",
    upgradeToRecommended: "Upgrade to recommended release:",
    auditLog: "Audit Logs",
    operationType: "Operation Type",
    objectType: "Object Type",
    object: "Object",
    user: "User",
    userRole: "User Role",
    userIp: "User IP",
    operationResult: "Operation Result",
    next: "Next",
    unknownInfo:
      'Serial Number of the Radio AP must be set to fetch the status. Else status will be shown as "Unknown".',
    editProfile: "Edit Profile",
    updateProfile_btn: "Update Profile",
    cpiInfoText: "A Certified Professional Installer (CPI) is required to approve and sign this installation.",
    highway9CpiInfoText: "Highway 9 Networks can assist and approve the indoor radio installation for demo purpose",
    uploadFile: "Upload File",
    importSubscribersDesc: "Upload file to import bulk subscribers",
    upload: "Upload",
    sign: "Sign",
    signed: "Signed",
    resign: "Re-sign",
    submit: "Submit",
    connectedAPs: "Radio APs",
    earfcn: "Channel",
    pci: "PCI",
    oldPassword: "Old Password",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
    warning: "Warning",
    simulatorWarningMessage:
      "The on-prem edge may not be reachable from the cloud. Are you sure you want to continue...",
    vmzWarningMessage: "The VMZ is still in PUBLISHING state...",
    QOSProfile: "QoS Profile",
    VMZ: "Virtual Mobility Zone",
    Profiles: "Profiles",
    VMZs: "Virtual Mobility Zones",
    update: "Update",
    deleteGroup: "Delete Group",
    addGroup: "Add Group",
    editGroup: "Edit Group",
    GBR: "Guaranteed Bit Rate(GBR)",
    GBRLabel: "GBR",
    nonGBR: "Non-Guaranteed Bit Rate(Non-GBR)",
    nonGBRLabel: "Non-GBR",
    MBR: "Maximum Bit Rate(MBR)",
    arp: "Allocation and Retention Priority(ARP)",
    PDB: "Packet Delay Budget(PDB)",
    PER: "Packet Error Rate(PER)",
    QCI: "QoS Class Identifier",
    editDevice: "Edit Device",
    deleteDevice: "Delete Device",
    deleteDeviceMessage:
      "The SIM Card with this IMSI will be unusabled forever once the device is deleted. Are you sure you want to delete this device",
    networkSetting: "Settings",
    resetEdge: "Reset Edge",
    resetPassword: "Reset Edge Password",
    resetPasswordOperation: "RESET_PASSWORD",
    mcc: "MCC",
    mnc: "MNC",
    tac: "TAC",
    sasUserId: "SAS User ID",
    connectionStatus: "Connection State",
    NetworkTypeInfo: "Pre-requisite: The Bridged Network should have a DHCP server for IP allocation",
    bandwidthText: "Bandwidth per Device per Connection:",
    bandwidthInfo:
      "This indicates the aggregated maximum bit rate (bandwidth) allowed per Device per session. This is the default MBR for Applications with None QoS Profile. The Maximum Bit Rate (MBR) for any Application with GBR or Non-GBR QoS Profile will be capped at this value.",
    ambr: "APN-AMBR",
    edgeLocationAndType: "{0} {1} {2}",
    GCP: "Google Cloud",
    AWS: "Amazon Cloud",
    ON_PREMISE: "Private Cloud",
    selectDeploymentLocation: "Select Deployment Location",
    deploymentType: "Deployment Type",
    virtualizedVMware: "Virtual Mobile Edge Appliance (VMware vCenter)",
    VMware_ESXi :"Virtual Mobile Edge Appliance (VMware ESXi)",
    Mobile_Edge_Appliance: "Physical Mobile Edge Appliance",
    ueAmbrInfo:
      "This indicates the aggregated maximum bit rate (bandwidth) allowed per Device for all the Applications with QoS Profile of type None.",
    localExitInfo:
      "Choose this option with caution. When local exit is enabled, the SIM authentication will be done by the Edge, but data plane connections will exit via the Radio uplink and will not go via the Edge. Enabling this will limit the control on datapath. Features like QoS policies, VMZs, etc cannot be used.",
    localExitDesc:
      "When enabled, the SIM authentication will be done by the Mobility Edge, but data will exit via the Radio uplink and will not go via the Edge. Enabling this will limit the control on datapath. Features like QoS policies, Virtual Mobility Zones, etc will not work.",
    managedByEdgeInfo:
      "The control and data functions like SIM Authentication, VMZs and QoS policy enforcement will be managed by the edge. The data plane connections will exit via the edge uplink and edge can enforce ACLs and QoS policies to control the traffic.",
    ueId: "UE ID",
    uplinkSinr: "Uplink SINR",
    pDownlinkCqi: "P Downlink CQI",
    sDownlinkCqi: "S Downlink CQI",
    pDownlinkMcs: "P Downlink MCS",
    sDownlinkMcs: "S Downlink MCS",
    uplinkMcs: "Uplink MCS",
    p1DownlinkBler: "P1 DownlinkBler",
    p2DownlinkBler: "P2 DownlinkBler",
    s1DownlinkBler: "S1 DownlinkBler",
    s2DownlinkBler: "S2 DownlinkBler",
    uplinkBler: "Uplink BLER",
    downlinkRate: "Downlink Rate",
    uplinkRate: "Uplink Rate",
    pathloss: "Pathloss",
    txPower: "Tx Power",
    SIMType: "SIM Type",
    SIMStatus: "SIM State",
    frequencySelectionText:
      "Frequency Selection Logic determines which properties should be given priority if there is a frequency conflict with a High Priority CBRS user. Properties with lower priority will be automatically tuned to avoid the conflict.",
    clearSettings: "Clear CPI Settings",
    AddEdgePool: "Add Edge Pool",
    EditEdgePool: "Edit Edge Pool",
    edgePoolName: "Edge Pool",
    isEdgeInactive: "Radio redirection will not work since the IPSec tunnel is not Active",
    comments: "COMMENTS",
    role: "Role",
    clearTime: "Clear Time",
    severity: "Severity",
    cause: "Cause",
    all: "All",
    edgeNetworkModeError: "Changing the network mode will require you to redeploy the edge with the new network mode.",
    edgeNetworkMode_GATEWAY_warning:
      "In Gateway mode, the data network interface of the edge must be connected to an access port.",
    edgeNetworkMode_NE_warning:
      "In Network Extension mode, the data network interface of the edge must be connected to a trunk port.",
    events: "Events",
    details: "Details",
    services: "Services",
    operatorDetails: "Carrier Details",
    secGWConfig: "Security Gateway",
    operatorCoreConfig: "Carrier Core",
    WhitelistIMSI: "Whitelist IMSI",
    enableWhitelist: "Enable whitelist",
    connectedRadios: "Connected Radios",
    cmpv2Config: "CMPV2",
    enableSupport: "Enable Support",
    disableSupport: "Disable Support",
    enableSupportDesc:
      "Enabling Remote Support permits Highway 9 Networks the ability to remotely troubleshoot the Highway 9 Mobile Cloud, Edges & Radios.",
    activeSupportChannel: "Activate Support Channel",
    deactiveSupportChannel: "Deactivate Support Channel",
    renewLease: "Renew Lease",
    supportChannelMessage: "Are you sure you want to Enable support channel for ",
    supportChannelDisableMessage: "Are you sure you want to Disable support channel for ",
    addNeighbor: "Add Neighbor",
    neighborsList: "Neighbors List",
    radioAlarmWarning: "There are some radio alarms. Please check the radio alarms before proceeding.",
    description: "Description",
    operators: "Operators",
    alert: "Alert",
    editOperatorConfirmationMessage:
      "Editing carrier configuration might result in service disturbance like call-drop/radio-reattach. Do you still want to continue?",
    mobilityProfileChangeInfo:
      "Any update in mobility profile parameter will trigger reboot of all the radios in the radio groups connected to this mobility profile",
    warningMessageForDisabledSerialNumber: "Waiting for Radio to be active and synced with the Mobile Cloud",
    sites: "Sites",
    hardwareInventory: "Hardware Inventory",
    flows: "Flows",
    sentBitrate: "Sent Bitrate",
    receivedBitrate: "Received Bitrate",
    sentBytes: "Sent Bytes",
    receivedBytes: "Received Bytes",
    sentPackets: "Sent Packets",
    receivedPackets: "Received Packets",
    enableAAAServiceConfirmationText: `When enable/disable AAA service option, few control services on the edge will be restarted. On enabling this option, devices in the default group will be dynamically mapped to a device group received from AAA server during device authenticated.\n \n Do you still want to continue?`,
    sasSignatureMessage_1: "",
    sasSignatureMessage_2: "Signature is Invalid",
    sasSignatureMessage_3: "Signature is Pending",
    sequenceNumber: "Sequence Number",
    latency: "Latency",
    jitter: "Jitter",
    packetSize: "Packet Size",
    generalInfo: "General Info",
    coreInfo: "Core Info",
    secGWInfo: "SecGW Info",
    operatorRadios: "Carrier Radios",
    carrierGateway: "Carrier Gateway",
    table: "Table",
    graph: "Graph",
    bandEarfcnConfig: "Band/Earfcn Configuration",
    testChart: "Test Chart",
    metricsList: "Metrics List",
    topBarSubtitle: "Mobile Cloud",
    totalConnectedDevicesTitle: "Total Connected Devices",
    totalConnectedDevicesSubtitlePartial: "across",
    coverageAreaTitle: "Coverage Area",
    coverageAreaSubtitlePartial: "Number of Radios:",
    averageUtilizationTitle: "Network Utilization",
    averageUtilizationSubtitle: "During active hours",
    peakConnectionsTitle: "Peak Number of Connections",
    peakConnectionsTooltip:
      "The max number of connections seen on each carrier network. This value can be helpful in understanding how heavily the network is being used in the selected time period.",
    dashboardRightPaneTitle: "Devices Connected",
    dashboardRightPaneVMZ: "VMZ:",
    dashboardRightPaneAll: "All",
    dashboardRightPaneZones: "Zones",
    dashboardRightPaneDevicesConnected: "Devices Connected",
    licenseAgreement: "License Agreement",
    allSites: "All Sites",
    noConnectionsInTimeRange: "No connections in the selected time range",
    radioGroups: "Radio Groups",
    viewInRadiosDashboard: "View in Radios Dashboard",
    viewByVendor:"View by Vendor",
    noVMZDataAvailable: "No VMZ data available",
    noDevicesWereSeen: "No devices were seen connected in the selected time range for",
    gotoVMZSettings: "Go to Virtual Mobility Zone Settings",
    highway9: "Highway 9",
    devicesConnected: "Devices Connected",
    edgeState: "Edge State",
    rfStatus: "RF Status",
    bandwidth: "Bandwidth",
    indoorRadioGroup: "Indoor Radio Group",
    outdoorRadioGroup: "Outdoor Radio Group",
    edgeConnectivity: "Edge Connectivity",
    radioCount: "Radio Count",
    radiosLable: "Radios",
    goToRadioDashboard: "Go to Radio Dashboard",
    goToRadioGroupDashboard: "Go to Radio Group Dashboard",
    goToRadioSiteDashboard: "Go to Radio Site Dashboard",
    goToEdgeDashboard: "Go to Edge dashboard",
    eventsTimeline: "Events Timeline",
    radioSite: "Radio Site",
    indoorRadio: "Indoor Radio",
    outdoorRadio: "Outdoor Radio",
    networkMap: "Network Map",
    overallHealth: "Overall Health",
    cloudConnectivity: "Cloud Connectivity",
    eci: "Cell ID",
    channelNoEARFCN: "Channel No. (Center Freq)",
    overallRadiosHealth: "Overall Radios Health",
    aggregationPeriod: "Aggregation Period",
    topKPI: "Top KPI",
    addImport:"Add/Import",
  },
  it: {
    radios: "Radio AP...",
    radioImportCheck: "Preserve old signature if there is no change in the radio settings",
  },
  hi: {
    title: "हाईवे9 नेटवर्क्स मोबाइल क्लाउड कंसोल",

    zone: {
      editGroup: "समूह संपादित करें",
      addGroup: "समूह जोड़ें",
      deleteZoneGroup: "क्या आप वाकई इस समूह को हटाना चाहते हैं?",
      deleteZone: "क्या आप वाकई क्षेत्र हटाना चाहते हैं ",
    },
    headerTitle: "हाईवे9 सर्विस इंस्टेंस कंसोल -",
    dashboard: "डैशबोर्ड",
    RAN: "Radio AP",
    metrics: "मैट्रिक्स",
    simulators: "सिम्युलेटर",
    administration: "प्रशासन",
    users: "उपयोगकर्ताओं",
    profile: "प्रोफ़ाइल",
    logout: "लॉग आउट",
    radios: "रेडियो एपी",
    tenants: "किरायेदारों",
    serviceInstances: "सेवा उदाहरण",
    edges: "किनारों",
    cloudType: "बादल प्रकार",
    location: "स्थान",
    subscribers: "ग्राहकों",
    DNN: "Network",
    policies: "नीतियों",
    data: "आंकड़े",
    alarms: "एलार्म",
    healthy: "स्वस्थ",
    total: "कुल",
    activated: "सक्रिय",
    dataSubText: "कुल",
    concatSubText: "{0}/{1}",
    name: "नाम",
    type: "प्रकार",
    health: "स्वास्थ्य",
    creationTime: "रचना समय",
    controller: "नियंत्रक",
    activeSubscribers: "सक्रिय सदस्य",
    edge: "किनारा",
    radio: "रेडियो",
    radioImportCheck: "Preserve old signature if there is no change in the radio settings",
  },
});

export const defaultvalues = {
  MCC: 315,
  MNC: 10,
};
