const e = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  innerContainer: {
    display: "flex",
    alignItems: "center"
  },
  dateTimeBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    border: "1px solid #EFEFEF",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    backgroundColor: "#F7F7F7",
    height: "32px",
    padding: "0px 8px",
    cursor: "pointer"
  },
  dateTimeBoxRightBorder: {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px"
  },
  activeDateTimeBox: {
    border: "1px solid #226A58",
    backgroundColor: "#E9F0EE"
  },
  liveIndicatorBox: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    borderRadius: "4px",
    height: "20px",
    padding: "0px 6px"
  },
  liveDot: {
    height: "6px",
    width: "6px",
    borderRadius: "100%",
    backgroundColor: "#D32F2F"
  },
  icon: {
    color: "#7A7A7A"
  },
  arrowIcon: {
    color: "#7A7A7A",
    fontSize: "16px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  dateRangeBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    border: "1px solid #EFEFEF",
    borderLeft: "none",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundColor: "#F7F7F7",
    height: "32px",
    padding: "0px 8px"
  },
  dateRangeLeftBorder: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    borderLeft: "1px solid #EFEFEF"
  },
  dateBox: {
    position: "relative",
    height: "100%"
  },
  dateText: {
    color: "#5B5B5B",
    fontWeight: 500,
    fontSize: 12,
    height: "100%",
    alignContent: "center",
    cursor: "pointer"
  },
  activeDateBorder: {
    height: "2px",
    width: "100%",
    borderRadius: "2px",
    backgroundColor: "#226A58",
    position: "absolute",
    bottom: 0
  },
  dateToLabel: {
    color: "#5B5B5B",
    fontWeight: 500,
    fontSize: 12,
    opacity: "0.4"
  },
  timeLabel: {
    color: "#5B5B5B",
    fontWeight: 500,
    fontSize: 12
  },
  liveLabel: {
    color: "#5B5B5B",
    fontWeight: 500,
    fontSize: 12
  },
  refreshButton: {
    textTransform: "none",
    backgroundColor: "#F7F7F7",
    border: "1px solid #226A58",
    color: "#226A58",
    borderRadius: "8px",
    width: "65px"
  },
  applyButton: {
    textTransform: "none",
    backgroundColor: "#226A58",
    color: "white",
    borderRadius: "8px",
    boxShadow: "none",
    width: "65px"
  },
  dateTimeSelectorMenuStyle: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      boxShadow: "0px 4px 16px 0px #00000010",
      marginTop: "6px",
      "& .MuiList-root": {
        padding: "10px"
      }
    }
  },
  dateTimeSelectorContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "6px",
    gap: "16px"
  },
  dateTimeSelectorsWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  monthYearSelectInput: {
    fontSize: "12px",
    fontWeight: 400
  },
  monthYearSelectBox: {
    width: "100%",
    height: "32px",
    border: "1px solid #CDD2DBB2",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& .month-year-selector": {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      fontWeight: 400
    },
    "& fieldset": {
      border: "none"
    }
  },
  monthYearMenuItem: {
    fontSize: "12px",
    fontWeight: 400
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F7F7F7",
    border: "1px solid #EFEFEF",
    borderRadius: "8px",
    height: "32px",
    width: "max-content"
  },
  datePickerContent: {
    display: "flex",
    alignItems: "center",
    padding: "0px 12px",
    gap: "8px"
  },
  verticalDivider: {
    borderColor: "#EFEFEF"
  },
  formattedDateText: {
    padding: "0px 16px",
    fontSize: "12px",
    fontWeight: 500
  },
  // Calender Styles
  calenderContainer: {
    "& .date-time-selector-calender": {
      "& .rdp-month_caption": {
        display: "none"
      },
      "& .rdp-weekday": {
        fontSize: "13px",
        fontWeight: "500",
        color: "#AEAEAE"
      },
      "& .rdp-day": {
        height: "32px",
        width: "32px",
        "& .rdp-day_button": {
          height: "32px",
          width: "32px",
          fontSize: "13px",
          fontWeight: "500",
          color: "#1D1B20"
        }
      },
      "& .rdp-selected": {
        "& .rdp-day_button": {
          border: "none",
          backgroundColor: "#226A58",
          color: "#ffffff"
        }
      }
    }
  }
};
export {
  e as styles
};
